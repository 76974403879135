import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Untersuchung1 from "../components/untersuchung1"
import Untersuchung2 from "../components/untersuchung2"
import Untersuchung3 from "../components/untersuchung3"
import Untersuchung4 from "../components/untersuchung4"
import Untersuchung5 from "../components/untersuchung5"

import "./index.css"

export default function UntersuchungPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Untersuchung"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Untersuchung"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Untersuchung</h1>
        <h2 className="referenceH2">
          Spätgotische Skulpturengruppe aus der Gruft von St. Walburg, Eichstätt
        </h2>
        <h3 className="referenceH3">
          Untersuchung, Visualisierung der Fassungsphasen, Konservierung,
          Retusche
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Untersuchung1">
              <Untersuchung1 id="Untersuchung1" />
              Vorzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Untersuchung2">
              <Untersuchung2 id="Untersuchung2" />
              Endzustand, eine neue Form der Präsentation wurde mit der
              Aufstellung vor dem blauen Hintergrund geschaffen
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Untersuchung3">
              <Untersuchung3 id="Untersuchung3" />
              Heilige Wuna Erstfassung von 1500
              <br />
              (Autorin Astrid Mendes)
            </label>
            <label htmlFor="Untersuchung4">
              <Untersuchung4 id="Untersuchung4" />
              Heilige Wuna erste Überfassung von 1741
              <br />
              (Autorin Astrid Mendes)
            </label>
            <label htmlFor="Untersuchung5">
              <Untersuchung5 id="Untersuchung5" />
              Heilige Wuna zweite Überfassung von 1870
              <br />
              (Autorin Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Es handelt sich um eine Figurengruppe, die sich früher in einem
              Schrein befand, der nicht mehr vorhanden ist. In der Mitte
              befindet sich die Darstellung der Hl. Walburga. Ihre Eltern stehen
              jeweils ganz außen. Links und rechts neben ihr stehen ihre Brüder
              Bischof Willibald von Eichstätt und Abt Wunibald.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die spätgotischen Plastiken zeigten drei differenziert gestaltete
              Farbfassungen, die mikroskopisch unter 60facher Vergrößerung
              nachgewiesen werden konnten. Das Ergebnis wurde in Form einer
              farbigen Visualisierung auf Papier festgehalten. Die kolorierten
              Zeichnungen wurden im Zuge eines Aufsatzes von Bettina Mayer in
              KulturGesichtenN Festschrift für Walter Pötzl zum 60. Geburtstag,
              Band 1 Alexandra Kohlberger (Hrsg.) S 540 – 544, 1999 publiziert.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Im Vordergrund stand die Konservierung und Retusche der
              Sichtfassung von 1870 und der darunter liegenden historischen
              Farbreste.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Konservierung, Kittung, Retusche, Abnahme von Bronzierungen,
              holzbildhauerische Ergänzung und Retusche. An den Skulpturen von
              St. Walburg wurden mit Hilfe der mikroskopischen Untersuchung drei
              historischen Farbfassungen festgestellt: Die erste Fassung war aus
              dem 15. Jahrhundert, die nächste von 1741 (Barock) und die
              Sichtfassung stammte von 1870. Die gesamten Ergebnisse der
              Untersuchung wurden zunächst in einer Stratigrafie erfasst. In
              dieser Tabellenform erfassten wir den technischen Aufbau und
              nahmen eine Charakterisierung der Farbfassungen vor. Dadurch
              können die an das Kunstwerk gestellten Fragestellungen in der
              Regel beantwortet werden. Die Ergebnisse wurden ausgewertet und
              dokumentiert, die Farbfassungen der verschiedenen Epochen wurden
              an allen Figuren mit Buntstiften visualisiert. Am Beispiel der
              seligen Wuna, einer der Skulpturen aus der fünfteiligen Gruppe,
              kann man die Unterschiede und den Zeitgeist der jeweiligen Epochen
              erkennen. Von Bedeutung sind auch Veränderungen, die durch
              hinzugefügte Attribute, wie ihre Krone, erfolgt sind.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">
              Staatliches Hochbauamt Eichstätt (Im Auftrag des Bistums
              Eichstätt).
            </p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Herr Dr. Emanuel Braun, Leiter des Diözesanmuseum Eichstätt,
              Nachfolger Frau Dr. Claudia Grund. Landesamt für Denkmalpflege,
              Werkstattleiter Restaurator Erwin Emmerling.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
